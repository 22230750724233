document.addEventListener('turbolinks:load', function (e) {
    var progressBar = document.getElementById('course_progress');

    var partsCount = progressBar.dataset.partsCount;
    var correctParts = progressBar.dataset.correctParts;
    var progress = correctParts / partsCount * 100;

    progressBar.style.width = progress + "%";
    progressBar.innerText = parseInt(progress) + "%";
})
