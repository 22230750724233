document.addEventListener('turbolinks:load', function (e){
    $(document).ready(function(){
        $("#collapsibleNavbar").on("hide.bs.collapse", function(){
            $(".btn").html('<span class="glyphicon glyphicon-collapse-down"></span> Open');
        });
        $("#collapsibleNavbar").on("show.bs.collapse", function(){
            $(".btn").html('<span class="glyphicon glyphicon-collapse-up"></span> Close');
        });
    });
})


// Accordion from index page
document.addEventListener('turbolinks:load', function(e){

  // $('.collapse').on('shown.bs.collapse', function(){
  //   $(this).parent().find(".fa-plus-circle").removeClass("fa-plus-circle").addClass("fa-minus-circle");
  // }).on('hidden.bs.collapse', function(){
  //   $(this).parent().find(".fa-minus-circle").removeClass("fa-minus-circle").addClass("fa-plus-circle");
  // });
  $('.card-header').click(function() {
    $(this).find('span').toggleClass('fa fa-plus-circle fa fa-minus-circle');
  });
})
