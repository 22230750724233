import $ from "jquery";

document.addEventListener('turbolinks:load', function (e){
    $('#display-courses').click(function (e){
        $('#display-profile').removeClass('active-user')
        $('#display-certificates').removeClass('active-user')
        $('#display-courses').addClass('active-user')

        $('#certificate').hide()
        $('#profile').hide()
        $('#course').show()
    })

    $('#display-profile').click(function (e){
        $('#display-courses').removeClass('active-user')
        $('#display-certificates').removeClass('active-user')
        $('#display-profile').addClass('active-user')

        $('#certificate').hide()
        $('#course').hide()
        $('#profile').show()
    })

    $('#display-certificates').click(function (e){
        $('#display-courses').removeClass('active-user')
        $('#display-profile').removeClass('active-user')
        $('#display-certificates').addClass('active-user')

        $('#profile').hide()
        $('#course').hide()
        $('#certificate').show()
    })
})
